import * as React from 'react'
import Layout from '../components/layout'

const AboutPage = () => {
  return (
    <Layout pageTitle={"About This Here Blog"}>
        <p>The intention of this log is chronical the going-ons of yet another person that roams this planet. Nothing spectacular beyond the presentation of a unique perspective, one that isn't drastically different from those in similar circumstances. We're all unique snowflakes that just so happen to made of frozen water, boil us down to our core we're all the same despite manifesting in wildly variant ways. </p>
        <p>This soap box is as much a creative outlet for me as painting might be for artists or playing a piano be for a musician. It's all about getting the energies of creativity flowing in order to bring shape to thoughts, emotions, and ideas that wiz pass one's mind's eye. Anyone who works in any form of medium; be it words like here, instruments, woods, canvas, yarn, etc.; will know that burning urge to bend reality into the form our mind conjures for us. </p>
    </Layout>
  )
}

export default AboutPage